import React from 'react';

const ClassOverview = React.lazy(() => import('../views/Class/Overview'));
const ListClass = React.lazy(() => import('../views/Class/ListClass'));
const AddClass = React.lazy(() => import('../views/Class/AddClass'));
const EditClass = React.lazy(() => import('../views/Class/EditClass'));
const AddSeries = React.lazy(() => import('../views/Class/AddSeries'));

const base = '/course';

export const routes = [
  { path: `${base}/`, exact: true, name: 'Overview', component: ClassOverview },
  { path: `${base}/list`, exact: true, name: 'Class List', component: ListClass },
  { path: `${base}/add`, exact: true, name: 'Add Class', component: AddClass },
  { path: `${base}/add/:classid/series`, exact: true, name: 'Add Series', component: AddSeries },

  { path: `${base}/:classid/edit`, exact: true, name: 'Edit Class', component: EditClass },
];

export const navs = [
  { path: `${base}/`, exact: true, name: 'Overview' },
  { path: `${base}/list`, exact: true, name: 'Course List' },
  { path: `${base}/add`, exact: false, name: 'Add Course' },
];
