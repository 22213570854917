import React, { useReducer } from 'react';
import PropTypes from 'prop-types';

const FilterReducer = (state, action) => {
  const key = action.type;
  return state[key].includes(action.id)
    ? { ...state, [key]: [...state[key]].filter((item) => item !== action.id) }
    : { ...state, [key]: [...state[key], action.id] };
};

const initialState = {};
export const FilterContext = React.createContext(initialState);
export const FilterProvider = ({ filters, children }) => {
  Object.keys(filters).forEach((key) => {
    initialState[key] = [];
  });

  const [selectedFilter, dispatch] = useReducer(FilterReducer, initialState);
  return (
    <FilterContext.Provider value={{ filters, selectedFilter, dispatch }}>
      {children}
    </FilterContext.Provider>
  );
};

FilterProvider.propTypes = {
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  children: PropTypes.node.isRequired,
};
