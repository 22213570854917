export const setColor = (props) => {
  let themeColor = props.theme.color.base;
  try {
    if (props.primary) return themeColor.primary._100;
    else if (props.secondary) return themeColor.secondary._100;
    else return themeColor.btn.primary;
  } catch (error) {
    return themeColor.primary._100;
  }
};

export const setTextColor = (props) => {
  let textColor = props.theme.color.text;
  if (textColor[props.color]) return textColor[props.color];
  else return 'inherit';
};

export const setBtnSize = (props) => {
  try {
    switch (props.size.toLowerCase()) {
      case 'extrasmall':
        return 'min-width: 90px !important; height: 32px; font-size: 12px !important;';
      case 'small':
        return 'min-width: 140px !important; height: 42px; font-size: 14px !important;';
      case 'large':
        return 'min-width: 200px !important; height: 60px; font-size: 18px !important;';
      default:
        return 'width: 160px !important; height: 48px; font-size: 16px !important;';
    }
  } catch (error) {
    return 'min-width: 160px !important; height: 48px; font-size: 16px !important;';
  }
};

export const setBtnStyle = (props) => {
  const themeColor = props.theme.color;
  try {
    const color = themeColor.base[props.color || 'primary'];

    if (props.outlined) {
      return `
        border: 2px solid ${color._100} !important;
        color: ${color._100} !important;
        &:hover {
          background-color: ${color._100} !important;
          color: ${themeColor.text.secondary} !important;
        }
      `;
    } else {
      return `
        background-color: ${color._100} !important;
        color: ${themeColor.text.secondary} !important;
      `;
    }
  } catch (error) {
    return `
      background-color: ${themeColor.base.primary._100} !important;
      color: ${themeColor.text.secondary} !important;
    `;
  }
};

export const setInputStyle = (props) => {
  let theme = props.theme;
  try {
    if (props.error) {
      return `
        border: 2px solid ${theme.color.base.danger._40};
        &:focus {
          border: 2px solid ${theme.color.base.danger._80} !important;
        }
      `;
    } else {
      return `
        border: 1.5px solid ${theme.color.base.primary._40};
        color: ${theme.color.text.primary};
        &:hover, &:focus {
          border: 1.5px solid ${theme.color.base.primary._60};
          border-color: ${theme.color.base.primary._60};
        }
      `;
    }
  } catch (error) {
    return `
      border: 1.5px solid ${theme.color.base.primary._40};
      color: ${theme.color.text.primary};
      &:hover, &:focus {
        border: 1.5px solid ${theme.color.base.primary._60};
        border-color: ${theme.color.base.primary._60};
      }
    `;
  }
};

export const setBgColor = (props) => {
  let themeColor = props.theme.color.bg;
  try {
    switch (props.bgColor.toLowerCase()) {
      case 'main':
        return themeColor.main;
      case 'primary':
        return themeColor.primary;
      case 'secondary':
        return themeColor.secondary;
      default:
        return themeColor.main;
    }
  } catch (error) {
    return themeColor.main;
  }
};
