import React from 'react';
import PropTypes from 'prop-types';
import CKEditor from 'ckeditor4-react';

const TextEditor = ({ value, onChange }) => {
  const handleChange = (event) => {
    onChange(event.editor.getData());
  };
  return <CKEditor data={value} onChange={handleChange} />;
};

export default TextEditor;

TextEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

TextEditor.defaultProps = {
  value: '',
};
