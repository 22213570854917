import styled from 'styled-components';
import { H1, size } from '@ysc/ys-styles';

export const Container = styled.div`
  width: 100%;
  display: flex;
  min-height: 100vh;
`;

export const Column = styled.div`
  width: 50%;
  &.left {
    @media (max-width: ${size.tabletL}) {
      display: none;
    }
  }
  &.right {
    @media (max-width: ${size.tabletL}) {
      width: 100%;
    }
  }
`;

export const LeftContainer = styled.div`
  position: relative;
  background-color: black;
  width: 100%;
  height: 100%;
`;

export const LeftTitle = styled(H1)`
  color: white;
  position: fixed;
  top: 30vh;
  padding-left: 10%;
`;
