import React from 'react';
import { CircularLoading } from '@ysc/ys-styles';
import { Container } from './styles';

const FormLoading = () => {
  return (
    <Container>
      <CircularLoading />
    </Container>
  );
};
export default FormLoading;
