import React from 'react';
import PropTypes from 'prop-types';
import { Container, Column, LeftContainer, LeftTitle } from './styles';

const DualLayout = ({ title, children }) => {
  return (
    <Container>
      <Column className="left">
        <LeftContainer>
          <LeftTitle className="title">{title}</LeftTitle>
        </LeftContainer>
      </Column>
      <Column className="right">{children}</Column>
    </Container>
  );
};

export default DualLayout;

DualLayout.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
