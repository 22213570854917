import React from 'react';

const AddLive = React.lazy(() => import('../views/Live/AddLive'));
const ListLive = React.lazy(() => import('../views/Live/ListLive'));
const LiveOverview = React.lazy(() => import('../views/Live/Overview'));
const EditLive = React.lazy(() => import('../views/Live/EditLive'));
const LiveDetail = React.lazy(() => import('../views/Live/LiveDetail'));

const AddLiveSchedule = React.lazy(() => import('../views/Live/AddLiveSchedule'));
const ListLiveSchedule = React.lazy(() => import('../views/Live/ListLiveSchedule'));

const base = '/live';

export const routes = [
  { path: `${base}/`, exact: true, name: 'Overview', component: LiveOverview },
  { path: `${base}/add`, exact: true, name: 'Add Live', component: AddLive },
  { path: `${base}/list`, exact: true, name: 'Live List', component: ListLive },
  { path: `${base}/edit/:id`, exact: true, name: 'Add Live', component: EditLive },
  { path: `${base}/detail/:id`, exact: true, name: 'Live Detail', component: LiveDetail },
  { path: `${base}/schedule/add`, exact: true, name: 'Add Schedule', component: AddLiveSchedule },
  {
    path: `${base}/schedule/list`,
    exact: true,
    name: 'List Schedule',
    component: ListLiveSchedule,
  },
];

export const navs = [
  { path: `${base}/`, exact: true, name: 'Overview' },
  { path: `${base}/add`, exact: true, name: 'Add Live' },
  { path: `${base}/list`, exact: false, name: 'Live List' },
  { path: `${base}/schedule/add`, exact: false, name: 'Add Schedule' },
  { path: `${base}/schedule/list`, exact: false, name: 'List Schedule' },
];
