import { notification } from 'antd';

const Notification = (type, title, message, duration) => {
  const currTime = new Date();
  const hours = currTime.getHours();
  const minutes = currTime.getMinutes();

  let className = 'colored-notification ';
  switch (type) {
    case 'error':
      className += 'error-notification';
      break;
    case 'warning':
      className += 'warning-notification';
      break;
    case 'success':
      className += 'success-notification';
      break;
    case 'info':
      className += 'info-notification';
      break;
    default:
      className += '';
  }

  notification[type]({
    message: title,
    description: `${hours}:${minutes} - ${message}`,
    duration,
    className,
    style: {
      borderRadius: '5px',
    },
  });
};

export default Notification;
