import React, { useContext } from 'react';
import { FormControl, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { Text } from '@ysc/ys-styles';
import { FilterContext } from './FilterContext';

const FilterContent = () => {
  const { filters, selectedFilter, dispatch } = useContext(FilterContext);

  return (
    <div>
      {Object.keys(filters).map((key, index) => {
        return (
          <FormControl key={index.toString()} component="fieldset" className="m-s">
            <Text bold>{key}</Text>
            <FormGroup>
              {filters[key].map((item) => {
                return (
                  <FormControlLabel
                    key={item.id}
                    label={item.name}
                    control={
                      <Checkbox
                        color="secondary"
                        checked={selectedFilter[key].includes(item.id)}
                        onChange={() => dispatch({ type: key, id: item.id })}
                      />
                    }
                  />
                );
              })}
            </FormGroup>
          </FormControl>
        );
      })}
    </div>
  );
};

export default FilterContent;
