import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import {
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Divider,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import EditIcon from '@material-ui/icons/Edit';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { H6, Text, CircularLoading } from '@ysc/ys-styles';
import TableTools from './TableTools';
import { SortContext } from './TableTools/SortContext';
import logo from '../../logo.svg';
import { TextHeader, ClassTitle, ClassImage, LoadMore } from './styles';

const renderSubcategories = (subcategories) => {
  if (!subcategories) return '';
  const lastIndex = subcategories.length - 1;
  return (
    <Text size="small">
      {subcategories.map((subcategory, index) => {
        return index !== lastIndex
          ? `${subcategory.subcategory_name}, `
          : subcategory.subcategory_name;
      })}
    </Text>
  );
};

const ClassTableButtons = ({ type = 'class', classid }) => {
  const history = useHistory();
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <IconButton>
        <AssessmentOutlinedIcon fontSize="small" />
      </IconButton>
      <IconButton onClick={() => history.push(`/${type}/${classid}/edit`)}>
        <EditIcon fontSize="small" />
      </IconButton>
      <IconButton>
        <PermIdentityOutlinedIcon fontSize="small" />
      </IconButton>
    </div>
  );
};

ClassTableButtons.propTypes = {
  classid: PropTypes.number,
  type: PropTypes.string,
};

ClassTableButtons.defaultProps = {
  classid: 0,
  type: 'class',
};

const ClassTable = ({ type, headers, rows, onLoadMore, isLoadingMore, showTools, isFetching }) => {
  const { dispatch } = useContext(SortContext);

  return isFetching && rows ? (
    'loading..'
  ) : (
    <div>
      {showTools && <TableTools />}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: '120px' }} />
              <TableCell />
              {headers.slice(1).map((header, index) => {
                return (
                  <TableCell key={parseInt(index, 10)} align={header.align}>
                    <TextHeader size="small" bold>
                      {header.fieldName}
                      {showTools && (
                        <IconButton
                          onClick={() => dispatch({ sortby: header.field, order: 'asc' })}
                        >
                          <ImportExportIcon fontSize="small" />
                        </IconButton>
                      )}
                    </TextHeader>
                  </TableCell>
                );
              })}
              <TableCell align="center">
                <TextHeader size="small" bold>
                  Status
                  {showTools && (
                    <IconButton onClick={() => dispatch({ sortby: 'visibility', order: 'asc' })}>
                      <ImportExportIcon fontSize="small" />
                    </IconButton>
                  )}
                </TextHeader>
              </TableCell>

              <TableCell />
            </TableRow>
          </TableHead>
          {/**
              Masalahnya disini */}
          <TableBody>
            {rows.map((row, index) => {
              return (
                <TableRow key={parseInt(index, 10)} height="50">
                  <TableCell width="140px">
                    <ClassImage src={logo} />
                  </TableCell>
                  <TableCell>
                    <ClassTitle bold>{row.title}</ClassTitle>
                    <Text size="small" bold color="red">
                      {row.category && JSON.parse(row.category).category_name}
                    </Text>
                    {row.subcategory && renderSubcategories(JSON.parse(row.subcategory))}
                  </TableCell>
                  {headers.slice(1).map((header, idx) => {
                    return (
                      <TableCell key={parseInt(idx, 10)} align={header.align}>
                        <Text size="small">{row[header.field]}</Text>
                      </TableCell>
                    );
                  })}
                  <TableCell align="center">
                    <Text size="small">{row.visibility ? 'Publised' : 'Hidden'}</Text>
                  </TableCell>
                  <TableCell>
                    <ClassTableButtons type={type} classid={row[headers[0].field]} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {onLoadMore &&
        (isLoadingMore ? (
          <LoadMore>
            <CircularLoading />
          </LoadMore>
        ) : (
          <LoadMore onClick={onLoadMore}>
            <H6>Load More</H6>
          </LoadMore>
        ))}
      <Divider />
    </div>
  );
};

export default ClassTable;

ClassTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.any),
  rows: PropTypes.arrayOf(PropTypes.any),
  onLoadMore: PropTypes.func,
  isLoadingMore: PropTypes.bool,
  showTools: PropTypes.bool,
  isFetching: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(['class', 'course']),
};

ClassTable.defaultProps = {
  headers: [],
  rows: [],
  onLoadMore: () => {},
  isLoadingMore: false,
  showTools: false,
  type: 'class',
};
