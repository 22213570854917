import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Option, CircularLoading } from '@ysc/ys-styles';

import { Containers, Select } from './styles';

import { GET } from '../../api';

const LocationInput = ({ onChange, value }) => {
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState(null);
  const [provinces, setProvinces] = useState([]);
  const [province, setProvince] = useState(null);
  const [cities, setCities] = useState([]);

  const [isFetchingCountry, setIsFetchingCountry] = useState(false);
  const [isFetchingProvince, setIsFetchingProvince] = useState(false);
  const [isFetchingCity, setIsFetchingCity] = useState(false);

  useEffect(() => {
    GET('/instructors/location', setCountries, setIsFetchingCountry);
  }, []);

  useEffect(() => {
    // reset form
    if (value === undefined) {
      setCountry(null);
      setProvinces([]);
      setProvince(null);
      setCities([]);
    }
  }, [value]);

  useEffect(() => {
    // set form
    if (value) {
      GET(`/instructors/location?city=${value}`, (info) => {
        GET(
          `/instructors/location?country=${info[0].country}`,
          setProvinces,
          setIsFetchingProvince
        );
        GET(
          `/instructors/location?country=${info[0].country}&province=${info[0].province}`,
          setCities,
          setIsFetchingCity
        );
        setCountry(info[0].country);
        setProvince(info[0].province);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleProvinces(place) {
    onChange(null);
    setCities([]);
    setProvince(null);
    GET(`/instructors/location?country=${place}`, setProvinces, setIsFetchingProvince);
    setCountry(place);
  }

  function handleCities(countryPlace, provincePlace) {
    onChange(null);
    GET(
      `/instructors/location?country=${countryPlace}&province=${provincePlace}`,
      setCities,
      setIsFetchingCity
    );
    // setProvince(province);
    setProvince(provincePlace);
  }

  const handleCityChange = (city) => {
    const newValue = {
      country,
      province,
      city,
    };
    onChange(newValue);
  };

  return (
    <Containers>
      {
        isFetchingCountry ? (
          <CircularLoading />
        ) : (
          countries.length > 0 && (
            <Select
              showSearch
              placeholder="Select Country ..."
              id="country"
              name="country"
              type="text"
              // onChange={(value) => {handleProvinces(value); formik.setValues({ ...formik.values, categoryid: value })}}
              onChange={(place) => {
                handleProvinces(place);
              }}
              // onBlur={formik.handleBlur}
              value={country}
              // disabled={disabled.includes("instructors")}
            >
              {countries.map((item) => {
                return (
                  <Option key={item.country} value={item.country}>
                    {item.country}
                  </Option>
                );
              })}
            </Select>
          )
        )
        // { (formik.touched.instructors && formik.errors.instructors) && <ErrorText>{formik.errors.instructors}</ErrorText>  }  */}
      }

      <br />

      {
        isFetchingProvince ? (
          <CircularLoading />
        ) : (
          provinces.length > 0 && (
            <Select
              showSearch
              placeholder="Select Province ..."
              id="province"
              name="province"
              type="text"
              // onChange={(value) => { formik.setValues({ ...formik.values, categoryid: value })}}
              onChange={(place) => {
                handleCities(country, place);
              }}
              // onBlur={formik.handleBlur}
              value={province}
              // disabled={disabled.includes("instructors")}
            >
              {provinces.map((item) => {
                return (
                  <Option key={item.province} value={item.province}>
                    {item.province}
                  </Option>
                );
              })}
            </Select>
          )
        )
        // {/* { (formik.touched.instructors && formik.errors.instructors) && <ErrorText>{formik.errors.instructors}</ErrorText>  } */}
      }

      <br />

      {
        isFetchingCity ? (
          <CircularLoading />
        ) : (
          cities.length > 0 && (
            <Select
              showSearch
              placeholder="Select City ..."
              id="city"
              name="city"
              type="text"
              // onChange={(value) => { formik.setValues({ ...formik.values, categoryid: value })}}
              onChange={handleCityChange}
              // onBlur={formik.handleBlur}
              value={value}
              // disabled={disabled.includes("instructors")}
            >
              {cities.map((item) => {
                return (
                  <Option key={item.city} value={item.city}>
                    {item.city}
                  </Option>
                );
              })}
            </Select>
          )
        )
        // {/* { (formik.touched.instructors && formik.errors.instructors) && <ErrorText>{formik.errors.instructors}</ErrorText>  } */}
      }
    </Containers>
  );
};

export default LocationInput;

LocationInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};
LocationInput.defaultProps = {
  value: undefined,
};
