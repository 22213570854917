import React from 'react';

const PodcastOverview = React.lazy(() => import('../views/Podcast/Overview'));
const ListPodcast = React.lazy(() => import('../views/Podcast/ListPodcast'));
const EditPodcast = React.lazy(() => import('../views/Podcast/EditPodcast'));
const AddPodcast = React.lazy(() => import('../views/Podcast/AddPodcast'));

const base = '/podcast';

export const routes = [
  { path: `${base}/`, exact: true, name: 'Overview', component: PodcastOverview },
  { path: `${base}/list`, exact: true, name: 'Podcast List', component: ListPodcast },
  { path: `${base}/add`, exact: true, name: 'Add Podcast', component: AddPodcast },
  { path: `${base}/edit/:podcastid`, exact: true, name: 'Add Podcast', component: EditPodcast },
];

export const navs = [
  { path: `${base}/`, exact: true, name: 'Overview' },
  { path: `${base}/list`, exact: false, name: 'Podcast List' },
  { path: `${base}/add`, exact: true, name: 'Add Podcast' },
];
