import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import { Text, H6, CircularLoading } from '@ysc/ys-styles';
import { useHistory } from 'react-router-dom';
import { ThinTableCell, LoadMore } from './styles';

const COLOR = {
  0: '#ffd65c',
  1: '#0da20d',
  '-1': '#f44336',
};

const BasicTable = ({
  headers,
  rows,
  showEdit,
  editRoute,
  detailRoute,
  onLoadMore,
  isLoadingMore,
  showDetail,
}) => {
  const history = useHistory();
  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <Text size="small" bold>
                  No
                </Text>
              </TableCell>
              {headers.slice(1).map((header) => {
                return (
                  <TableCell key={header.fieldName} align={header.align}>
                    <Text size="small" bold>
                      {header.fieldName}
                    </Text>
                  </TableCell>
                );
              })}
              {showEdit && <TableCell width="50px" />}
              {showDetail && <TableCell width="50px" />}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              return (
                <TableRow
                  key={row[headers[0].field]}
                  height="50"
                  onClick={() => {
                    if (detailRoute) history.push(`${detailRoute}/${row[headers[0].field]}`);
                  }}
                >
                  <TableCell align="center">{index + 1}</TableCell>

                  {headers.slice(1).map((header, idx) => {
                    return (
                      <ThinTableCell key={parseInt(idx, 10)} align={header.align}>
                        <Text
                          size="small"
                          style={header.withColor && { color: COLOR[row[header.colorRefTo]] }}
                        >
                          {row[header.field]}
                        </Text>
                      </ThinTableCell>
                    );
                  })}
                  {showEdit && (
                    <TableCell>
                      <IconButton
                        onClick={() => history.push(`${editRoute}/${row[headers[0].field]}`)}
                      >
                        <EditIcon fontSize="small" keys={row[headers[0].field]} />
                      </IconButton>
                    </TableCell>
                  )}
                  {showDetail && (
                    <TableCell>
                      <IconButton
                        onClick={() => history.push(`/event/${row[headers[0].field]}/detail`)}
                      >
                        <InfoIcon fontSize="small" keys={row[headers[0].field]} />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {onLoadMore &&
        (isLoadingMore ? (
          <LoadMore>
            <CircularLoading />
          </LoadMore>
        ) : (
          <LoadMore onClick={onLoadMore}>
            <H6>Load More</H6>
          </LoadMore>
        ))}
      <Divider />
    </div>
  );
};

export default BasicTable;

BasicTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.any),
  rows: PropTypes.arrayOf(PropTypes.any),
  showEdit: PropTypes.bool,
  showDetail: PropTypes.bool,
  editRoute: PropTypes.string,
  detailRoute: PropTypes.string,
  onLoadMore: PropTypes.func,
  isLoadingMore: PropTypes.bool,
};

BasicTable.defaultProps = {
  headers: [],
  rows: [],
  showEdit: false,
  showDetail: false,
  editRoute: '',
  detailRoute: '',
  onLoadMore: () => {},
  isLoadingMore: false,
};
