import React, { useState, Suspense } from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Switch, Route } from 'react-router-dom';

import SideDrawer from './SideDrawer';
import TheTabs from './TheTabs';
import TheContent from './TheContent';
import { FlexContainer, MainContainer, Stretcher, DrawerOpener, drawerWidth } from './styles';

const Login = React.lazy(() => import('../views/Login'));

const TheLayout = () => {
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Suspense fallback={<div>Loading.....</div>}>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/">
          <FlexContainer>
            <SideDrawer
              open={open}
              handleOpen={handleDrawerOpen}
              handleClose={handleDrawerClose}
              drawerWidth={drawerWidth}
            />
            <Stretcher open={open}>
              {!open && (
                <DrawerOpener onClick={handleDrawerOpen} color="secondary" aria-label="open">
                  <ChevronRightIcon />
                </DrawerOpener>
              )}
              <Suspense fallback={<div>Loading.....</div>}>
                <MainContainer>
                  <TheTabs />
                  <TheContent />
                </MainContainer>
              </Suspense>
            </Stretcher>
          </FlexContainer>
        </Route>
      </Switch>
    </Suspense>
  );
};

export default TheLayout;
