/* eslint-disable no-underscore-dangle */
import React from 'react';
import styled from 'styled-components';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { NavLink } from 'react-router-dom';
import { Text } from '@ysc/ys-styles';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import MicNoneOutlinedIcon from '@material-ui/icons/MicNoneOutlined';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import PaymentIcon from '@material-ui/icons/PaymentOutlined';

import Session from '../../auth';

const activeClassName = 'nav-item-active';
const StyledNavLink = styled(NavLink).attrs({ activeClassName })`
  &&& {
    text-decoration: none;
    color: inherit;
    &.${activeClassName}, &:hover {
      div {
        background-color: ${(props) => props.theme.color.base.primary._80};
      }
    }
  }
`;

const StyledListItem = styled(ListItem)`
  padding: 16px !important;
  padding-left: 60px !important;
`;

const ListItemText = styled(Text)`
  color: ${(props) => props.theme.color.text.secondary} !important;
  padding-left: 10px;
`;

const ListItemIcon = styled.span`
  color: ${(props) => props.theme.color.text.secondary} !important;
`;

const navs = [
  { title: 'Overview', icon: <AssessmentOutlinedIcon />, path: '/overview' },
  { title: 'User', icon: <PersonOutlineOutlinedIcon />, path: '/user' },
  { title: 'Instructor', icon: <AccountCircleOutlinedIcon />, path: '/instructor' },
  { title: 'Class', icon: <PlayCircleOutlineIcon />, path: '/class' },
  { title: 'Course', icon: <PlayCircleOutlineIcon />, path: '/course' },
  { title: 'Video', icon: <VideoLibraryIcon />, path: '/video' },
  { title: 'Podcast', icon: <MicNoneOutlinedIcon />, path: '/podcast' },
  { title: 'Studio', icon: <RoomOutlinedIcon />, path: '/studio' },
  { title: 'Plans/Pricing', icon: <LocalOfferOutlinedIcon />, path: '/planning' },
  { title: 'Live', icon: <LiveTvIcon />, path: '/live' },
  { title: 'Event', icon: <LocalOfferOutlinedIcon />, path: '/event' },
  { title: 'Transaction', icon: <PaymentIcon />, path: '/transaction' },
];

const DrawerList = () => (
  <List component="nav" aria-label="main mailbox folders">
    {navs.map((nav, index) => {
      return (
        <StyledNavLink to={nav.path} key={parseInt(index, 10)}>
          <StyledListItem button>
            <ListItemIcon>{nav.icon}</ListItemIcon>
            <ListItemText>{nav.title}</ListItemText>
          </StyledListItem>
        </StyledNavLink>
      );
    })}
    <StyledNavLink to="/login">
      <StyledListItem
        onClick={() => {
          Session.requestLogout();
        }}
      >
        <ListItemIcon>
          <ExitToAppOutlinedIcon />
        </ListItemIcon>
        <ListItemText>Logout</ListItemText>
      </StyledListItem>
    </StyledNavLink>
  </List>
);

export default DrawerList;
