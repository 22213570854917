import styled from 'styled-components';
import { Select as select } from './Field';

// FormLoading
export const Container = styled.div`
  height: calc(100vh - 300px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

// LocationInput
export const Containers = styled.div`
  width: 100%;
`;

export const Select = styled(select).attrs({ className: 'mb-s' })``;
