import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { mainTheme, GlobalStyles } from '@ysc/ys-styles';
import TheLayout from './containers/TheLayout';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'

function App() {
  return (
    <ThemeProvider theme={mainTheme}>
      <GlobalStyles />
      <BrowserRouter>
        <TheLayout />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
