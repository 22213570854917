import React from 'react';

const InstructorOverview = React.lazy(() => import('../views/Instructor/Overview'));
const ListInstructor = React.lazy(() => import('../views/Instructor/ListInstructor'));
const InstructorDetail = React.lazy(() => import('../views/Instructor/InstructorDetail'));
const AddInstructor = React.lazy(() => import('../views/Instructor/AddInstructor'));
const EditInstructor = React.lazy(() => import('../views/Instructor/EditInstructor'));

const base = '/instructor';

export const routes = [
  { path: `${base}/`, exact: true, name: 'Overview', component: InstructorOverview },
  { path: `${base}/add`, exact: true, name: 'Add Instructor', component: AddInstructor },
  { path: `${base}/list`, exact: true, name: 'Instructor List', component: ListInstructor },
  {
    path: `${base}/list/:instructorid`,
    exact: true,
    name: 'Instructor Detail',
    component: InstructorDetail,
  },
  {
    path: `${base}/edit/:instructorid`,
    exact: true,
    name: 'Edit Instructor',
    component: EditInstructor,
  },
];

export const navs = [
  { path: `${base}/`, exact: true, name: 'Overview' },
  { path: `${base}/add`, exact: true, name: 'Add Instructor' },
  { path: `${base}/list`, exact: false, name: 'Instructor List' },
];
