import React from 'react';
import PropTypes from 'prop-types';
import { Box as StyledBox, H2, Text } from '@ysc/ys-styles';

const Box = ({ title, children, color, className }) => {
  return (
    <StyledBox className={className} color={color}>
      <Text>{title}</Text>
      <H2>{children}</H2>
    </StyledBox>
  );
};

export default Box;

Box.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};
