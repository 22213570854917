import React, { useReducer } from 'react';
import PropTypes from 'prop-types';

const changeOrder = (currOrder) => {
  return currOrder === 'asc' ? 'desc' : 'asc';
};

const SortReducer = (state, action) => {
  return state.sortby === action.sortby
    ? { ...state, order: changeOrder(state.order) }
    : { ...state, sortby: action.sortby, order: 'asc' };
};

const initialState = {
  sortby: null,
  order: null,
};
export const SortContext = React.createContext(initialState);

export const SortProvider = ({ children }) => {
  const [sorting, dispatch] = useReducer(SortReducer, initialState);
  return <SortContext.Provider value={{ sorting, dispatch }}>{children}</SortContext.Provider>;
};

SortProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
