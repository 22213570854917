import React from 'react';
import { routes as mainRoutes } from '../routes/main';
import AdminRoute from '../routes/AdminRoute';

const TheContent = () => {
  return mainRoutes.map((route) => (
    <AdminRoute key={route.path} path={route.path} component={route.view} exact={route.exact} />
  ));
};
export default TheContent;
