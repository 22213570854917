import React from 'react';

const CouponOverview = React.lazy(() => import('../views/Coupon/Overview'));
const AddPolicy = React.lazy(() => import('../views/Coupon/policy/AddPolicy'));
const EditPolicy = React.lazy(() => import('../views/Coupon/policy/EditPolicy'));
const ListPolicy = React.lazy(() => import('../views/Coupon/policy/ListPolicy'));
const CreateCoupon = React.lazy(() => import('../views/Coupon/CreateCoupon'));
const ListCoupon = React.lazy(() => import('../views/Coupon/ListCoupon'));
const EditCoupon = React.lazy(() => import('../views/Coupon/EditCoupon'));

const base = '/coupon';

export const routes = [
  { path: `${base}/`, exact: true, name: 'Overview', component: CouponOverview },
  { path: `${base}/policy/add`, exact: true, name: 'Add Policy', component: AddPolicy },
  {
    path: `${base}/policy/:policyid/edit`,
    exact: true,
    name: 'Edit Policy',
    component: EditPolicy,
  },
  { path: `${base}/policy/list`, exact: true, name: 'List Policy', component: ListPolicy },
  { path: `${base}/add`, exact: true, name: 'Create Coupon', component: CreateCoupon },
  { path: `${base}/list`, exact: true, name: 'List Coupon', component: ListCoupon },
  { path: `${base}/edit/:couponid`, exact: true, name: 'Edit Coupon', component: EditCoupon },
];

export const navs = [
  { path: `${base}/`, exact: true, name: 'Overview' },
  { path: `${base}/policy/add`, exact: true, name: 'Add Policy' },
  { path: `${base}/policy/list`, exact: true, name: 'List Policy' },
  { path: `${base}/add`, exact: false, name: 'Create Coupon' },
  { path: `${base}/list`, exact: false, name: 'List Coupon' },
  { path: `${base}/policy`, exact: true, name: 'Policy' },
];
