import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@ysc/ys-styles';
import { Grow } from '@material-ui/core';
import { CardContainer, CardContent, CardAction } from './styles';

const ActionCard = ({ open, icon, content, children }) => {
  return (
    <Grow in={open}>
      <CardContainer>
        <CardContent className="pb-s">
          <span className="pr-xs">{icon}</span>
          <Text size="small">{content}</Text>
        </CardContent>
        <CardAction className="pb-s pl-s">{children}</CardAction>
      </CardContainer>
    </Grow>
  );
};

export default ActionCard;

ActionCard.propTypes = {
  open: PropTypes.bool,
  icon: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

ActionCard.defaultProps = {
  open: false,
};
