import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import { Text } from '@ysc/ys-styles';

const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const ModalScreen = ({ open, handleClose, title, children }) => (
  <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style}>
      <Text id="modal-modal-title">{title}</Text>
      {children}
    </Box>
  </Modal>
);

export default ModalScreen;

ModalScreen.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};
ModalScreen.defaultProps = {
  open: false,
  handleClose: false,
  title: '',
};
