import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DrawerList from './DrawerList';
import { StyledSideDrawer, StyledIconButton } from './styles';

const SideDrawer = ({ open, handleClose, drawerWidth }) => {
  const toggleDrawer = () => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    handleClose();
  };

  return (
    <StyledSideDrawer
      drawerWidth={drawerWidth}
      variant="persistent"
      anchor="left"
      open={open}
      onClose={toggleDrawer(false)}
    >
      <DrawerList />

      <Divider />

      <StyledIconButton onClick={handleClose}>
        <ChevronLeftIcon />
      </StyledIconButton>
    </StyledSideDrawer>
  );
};

export default SideDrawer;

SideDrawer.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  drawerWidth: PropTypes.string,
};

SideDrawer.defaultProps = {
  open: false,
  handleClose: () => {},
  drawerWidth: '',
};
