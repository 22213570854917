import styled from 'styled-components';
import { FlexContainer, Text } from '@ysc/ys-styles';
import { NavLink } from 'react-router-dom';

export const CardContainer = styled.div.attrs({ className: 'pt-s pl-s pr-s' })`
  max-width: 600px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 3px 0px rgb(0 0 0 / 12%);
`;

export const CardContent = styled.div`
  display: flex;
  text-align: left;
  align-items: center;
  flex-grow: 3;
`;

export const CardAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 2;
`;

// Navbar
export const NavsContainer = styled(FlexContainer)`
  &&& {
    min-width: 100%;
    height: 60px;
    overflow-x: scroll;
    scrollbar-width: none;
    ms-overflow-style: none;
  }
`;

const activeClassName = 'nav-item-active';
export const StyledNavLink = styled(NavLink).attrs({ activeClassName })`
  &&& {
    text-decoration: none;
    white-space: nowrap;
    color: inherit;
    padding: 20px;
    z-index: 100;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    height: 100%;
    &.${activeClassName}, &:hover {
      border-bottom: 2px solid ${(props) => props.theme.color.text.primary};
    }
    &.${activeClassName} {
      div p {
        font-weight: bold;
      }
    }
  }
`;

export const BorderBottom = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.color.text.primary}; ;
`;

// ProfileDetail
export const Label = styled(Text).attrs({
  size: 'small',
  className: 'p-xs',
})`
  min-width: 125px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
`;

export const Value = styled(Text).attrs({
  size: 'small',
  bold: true,
  className: 'p-xs',
})`
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const ImageProfile = styled.img`
  width: 100%;
  padding: 10px;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1400px;
`;

export const NameContainer = styled.div`
  width: 500;
  padding: 15px;
  line-height: 5px;
  font-size: 24px;
  font-weight: bold;
  margin-top: 25px;
`;
export const SubstitleContainer = styled.div`
  width: 500;
  padding-left: 15px;
  line-height: 50px;
  font-size: 18px;
  color: #828282;
  margin-bottom: 15px;
`;

export const ImageContainer = styled.div`
  box-sizing: border-box;
  max-width: 330px;
  // background-color: green;
`;

export const FieldContainer = styled.div`
  box-sizing: border-box;
  flex-grow: 1;
  text-transform: capitalize;
`;

export const Field = styled.div.attrs({
  className: 'pt-xs',
})`
  display: flex;
  flex-wrap: wrap;
  // background-color: green;
`;
