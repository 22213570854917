import React from 'react';

const HealthOverview = React.lazy(() => import('../views/Health/Overview'));
const HealthCreate = React.lazy(() => import('../views/Health/CreateHealth'));
const ListHealth = React.lazy(() => import('../views/Health/ListHealth'));

const base = '/health';

export const routes = [
  { path: `${base}/`, exact: true, name: 'Health Overview', component: HealthOverview },
  { path: `${base}/create`, exact: true, name: 'Health Create', component: HealthCreate },
  { path: `${base}/list`, exact: true, name: 'List Health', component: ListHealth },
];

export const navs = [
  { path: `${base}/`, exact: true, name: 'Overview' },
  { path: `${base}/create`, exact: true, name: 'Health Create' },
  { path: `${base}/list`, exact: true, name: 'List Health' },
];
