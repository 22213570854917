import styled, { css } from 'styled-components';
import { Input as input, Select as select, TextArea as textarea, Text } from '@ysc/ys-styles';
import { Switch } from 'antd';

export const Field = styled.div.attrs({ className: 'mt-s' })`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 900px;
`;

export const InputContainer = styled.div`
  box-sizing: border-box;
  max-width: 700px;
  flex-grow: 1;
  flex-basis: calc((500px - 100%) * 999);
`;

export const inputStyle = css`
  width: 100%;
  box-sizing: border-box;
`;

export const Input = styled(input)`
  ${inputStyle}
`;

export const Select = styled(select)`
  ${inputStyle}
`;

export const Toogle = styled(Switch)``;

export const TextArea = styled(textarea)`
  ${inputStyle}
`;

export const Label = styled.label`
  width: 30%;
  min-width: 150px;
`;

export const LabelText = styled(Text).attrs({
  size: 'small',
  bold: true,
})``;

export const InputLocation = styled.div`
  ${InputContainer}
  flex-direction: column;
`;
