import styled, { createGlobalStyle, css } from 'styled-components';
import { setBtnStyle, setBtnSize, setInputStyle, setTextColor } from './utils';
import {
  Button as Btn,
  CircularProgress,
  LinearProgress as MuiLinearProgress,
} from '@material-ui/core';
import { device } from './breakpoint';
import { spacing } from './spacing';
import DatePicker from 'antd/es/date-picker';
import TimePicker from 'antd/es/time-picker';
import AntSelect from 'antd/es/select';
import AntSwitch from 'antd/es/switch';
import moment from 'moment';

const { RangePicker } = TimePicker;

import main from './theme/main';

export { device, size } from './breakpoint';
export const mainTheme = main;

const notification = css`
  &.colored-notification {
    color: white !important;
    font-family: Montserrat;
    align-items: center;
    div,
    svg {
      color: white !important;
    }
    span {
      line-height: 50px;
    }
  }
  &.error-notification {
    background-color: ${(props) => props.theme.color.base.danger._100};
  }
  &.warning-notification {
    background-color: ${(props) => props.theme.color.base.warning._100};
  }
  &.info-notification {
    background-color: ${(props) => props.theme.color.base.info._100};
  }
  &.success-notification {
    background-color: ${(props) => props.theme.color.base.success._100};
  }
`;

const shadow = css`
  &.shadow-s {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25) !important;
  }
  &.shadow-m {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25) !important;
  }
  &.shadow-l {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25) !important;
  }
  &.shadow-xl {
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.25) !important;
  }
`;

export const GlobalStyles = createGlobalStyle`
    html {
      height: 100%;
    }
    body, #root, .App {
      background: ${({ theme }) => theme.color.main};
      color: ${({ theme }) => theme.color.text.primary};
      min-height: 100vh;
    }
    a, div {
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    }
    ${spacing}
    ${shadow}
    ${notification}
    // fix ant design select dropdown not showing
    &.ant-select-dropdown {
      opacity: 1 !important;
      pointer-events: unset !important;
    }
    // style time picker panel
    &.ant-picker-time-panel-column {
      &::-webkit-scrollbar {
        display: none;
      }
    }
`;

// typography
const heading = css`
  ${(props) => {
    if (props.bold) return 'font-weight: 700 !important;';
    else if (props.semibold) return 'font-weight: 600 !important;';
  }}
  color: ${(props) => setTextColor(props)};
  font-family: Montserrat;
  font-weight: 700;
  margin-top: 5px;
  margin-bottom: 5px;
`;
/**
 * &:hover {
    text-decoration: underline;
  }
 */
export const H1 = styled.h1`
  ${heading}
  font-size: 34px;
`;
export const H2 = styled.h2`
  ${heading}
  font-size: 30px;
`;
export const H3 = styled.h3`
  ${heading}
  font-size: 24px;
`;
export const H4 = styled.h4`
  ${heading}
  font-size: 22px;
`;
export const H5 = styled.h5`
  ${heading}
  font-size: 18px;
`;
export const H6 = styled.h6`
  ${heading}
  font-size: 16px;
`;
export const Text = styled.p`
  ${(props) => {
    if (props.size === 'large') return 'font-size: 18px;';
    else if (props.size === 'small') return 'font-size: 14px;';
    else if (props.size === 'extrasmall') return 'font-size: 12px;';
    else return 'font-size: 16px;';
  }}
  ${(props) => {
    if (props.bold) return 'font-weight: 700 !important;';
    else if (props.semibold) return 'font-weight: 600 !important;';
  }}
  color: ${(props) => setTextColor(props)};
  margin-top: 0;
  margin-bottom: 2px;
  font-family: Montserrat;
  font-weight: 400;
`;

export const ErrorText = styled(Text).attrs({
  color: 'danger',
  size: 'extrasmall',
})``;

export const CircularLoading = styled(CircularProgress)`
  &&& {
    color: ${(props) =>
      props.colorHex || props.theme.color.base[props.color || 'primary']._100} !important;
    width: ${(props) => props.size || '30px'} !important;
    height: ${(props) => props.size || '30px'} !important;
  }
`;

export const LinearLoading = styled(MuiLinearProgress)`
  height: 3px !important;
  background-color: ${(props) => props.theme.color.base.primary._40} !important;
  div {
    background-color: ${(props) => props.theme.color.base.primary._80} !important;
  }
`;

export const LinearProgress = styled(LinearLoading).attrs({
  variant: 'determinate',
})``;

export const Box = styled.div.attrs({
  className: 'p-s',
})`
  background-color: ${(props) => props.color};
  width: 200px;
  height: 95px;
  box-sixing: border-box;
  flex: 0 0 auto;
  text-align: left !important;
  p,
  h2 {
    color: ${(props) => props.theme.color.text.secondary} !important;
    margin: 0;
  }
`;

/*
export const Btn = styled(({ ...props }) => (
  <Button {...props} variant='outlined' />
))`
  font-family: 'Montserrat' !important;
  ${props => setBtnSize(props)}
  ${props => setBtnStyle(props)}
`
*/
export const Button = styled(Btn).attrs((props) => ({
  variant: props.outlined ? 'outlined' : 'contained',
}))`
  font-family: Montserrat !important;
  text-transform: capitalize !important;
  opacity: ${(props) => (props.disabled ? '0.7' : '1')};
  ${(props) => setBtnSize(props)}
  ${(props) => setBtnStyle(props)}
`;

const inputField = css`
  ${(props) => setInputStyle(props)}
  outline: none;
  padding-left: 8px;
  padding-right: 8px;
  align-items: center;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: none;
  font-family: 'Montserrat';
  transition: 0.5s ease-in-out;
`;

export const Input = styled.input.attrs({
  spellCheck: 'false',
})`
  ${inputField}
  height: 40px;
`;

// export const Select = styled.select.attrs(props => ({
//   spellCheck: 'false'
// }))`
//   ${inputField}
// `

export const Select = styled(AntSelect)`
  &&& {
    .ant-select-selector {
      ${inputField}
      height: 40px;
    }
  }
`;

export const { Option } = AntSelect;

export const Switch = styled(AntSwitch)`
  &.ant-switch-checked {
    background-color: ${({ theme }) => theme.color.base.brand._100} !important;
  }
`;

export const TextArea = styled.textarea.attrs({
  spellCheck: 'false',
})`
  ${inputField}
`;

export const TimeInput = styled(TimePicker)`
  ${inputField}
  height: 40px;
`;

export const TimeRangeInput = styled(RangePicker)`
  ${inputField}
  height: 40px;
`;

export const DateInput = styled(DatePicker)`
  ${inputField}
  height: 40px;
`;

export const Skeleton = styled.div`
  width: ${(props) => props.width || '100%'};
  border-radius: ${(props) => props.borderRad || '5px'};
  padding-top: ${(props) => props.heightRatio || '0'};
  height: ${(props) => props.height || (props.heightRatio ? '0px' : '32px')};
  background: rgb(226, 232, 240);
  background: linear-gradient(
    90deg,
    rgba(230, 230, 230, 0.8) 25%,
    rgba(210, 210, 210, 0.9) 37%,
    rgba(230, 230, 230, 0.8) 63%
  );
  background-size: 400% 100%;
  animation: loading-animation 2s ease infinite;
  @keyframes loading-animation {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

// Part of TimeInput and DateInput - disable moment when dateString == '' to prevent formik and InputDate error
export const momentConverter = (dateString) => {
  return dateString === '' ? '' : moment(dateString);
};

export const FlexContainer = styled.div`
  &&& {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none !important;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`;

export const MainContainer = styled.div`
  margin-right: auto;
  margin-left: auto;
  padding: 0 15px;
  box-sizing: border-box;
  width: 100%;
  //600px
  @media ${device.mobileL} {
    max-width: 100%;
  }
  //768px
  @media ${device.tabletS} {
    max-width: 860px;
  }
  //968px
  @media ${device.tabletM} {
    max-width: 900px;
  }
  //1024px
  @media ${device.tabletL} {
    max-width: 90%;
  }
  //1200px
  @media ${device.laptopS} {
    max-width: 90%;
  }
  //1440px
  @media ${device.laptopM} {
    max-width: 1480px;
  }
  //1689px
  @media ${device.laptop} {
    max-width: 1600px;
  }
`;

export const GridContainer = styled.div.attrs({
  className: 'p-xs',
})`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  // > 320px
  @media ${device.mobileS} {
    max-width: 100%;
  }
  // > 480px
  @media ${device.mobileM} {
    max-width: 440px;
  }
  // > 600px
  @media ${device.mobileL} {
    max-width: 100%;
  }
  // > 768px
  @media ${device.tabletS} {
    max-width: 1000px;
  }
  // > 1200px
  @media ${device.laptopS} {
    max-width: 1260px;
  }
`;

export const GridItem = styled.div.attrs({
  className: 'p-s',
})`
  box-sizing: border-box;
  width: 100%;
  @media ${device.mobileS} {
    width: 100%;
  }
  @media ${device.mobileL} {
    width: 50%;
  }
  @media ${device.tabletM} {
    width: 33.33%;
  }
`;
