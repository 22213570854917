import React from 'react';
import { useHistory, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import Session from '../../auth';

const AdminRoute = ({ path, component: Component }) => {
  const history = useHistory();
  return (
    <Route
      path={path}
      render={() => {
        return Session.isAdmin() ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { referrer: history.location.pathname },
            }}
          />
        );
      }}
    />
  );
};

export default AdminRoute;

AdminRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
};
