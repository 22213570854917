import React from 'react';
import { navs as classNavs } from './class';
import { navs as videoNavs } from './video';
import { navs as courseNavs } from './course';
// import { navs as userNavs } from './user';
import { navs as instructorNavs } from './instructor';
import { navs as planningNavs } from './planning';
import { navs as studioNavs } from './studio';
import { navs as podcastNavs } from './podcast';
import { navs as liveNavs } from './live';
import { navs as eventNavs } from './event';
import { navs as transactionNavs } from './transaction';
import { navs as couponNavs } from './coupon';
import { navs as giftcardNavs } from './giftcard';
import { navs as HealthNavs } from './health';

const Home = React.lazy(() => import('../views/Home'));
const Class = React.lazy(() => import('../views/Class'));
const Video = React.lazy(() => import('../views/Video'));
const Course = React.lazy(() => import('../views/Course'));
const Instructor = React.lazy(() => import('../views/Instructor'));
const Planning = React.lazy(() => import('../views/Planning'));
const Studio = React.lazy(() => import('../views/Studio'));
const Podcast = React.lazy(() => import('../views/Podcast'));
// const User = React.lazy(() => import('../views/User'));
const Live = React.lazy(() => import('../views/Live'));
const Event = React.lazy(() => import('../views/Event'));
const Login = React.lazy(() => import('../views/Login'));
const Transaction = React.lazy(() => import('../views/Transaction'));
const Coupon = React.lazy(() => import('../views/Coupon'));
const Giftcard = React.lazy(() => import('../views/Giftcard'));
const Health = React.lazy(() => import('../views/Health'));

export const routes = [
  { path: '/', name: 'Home', view: Home, exact: true },
  { path: '/class', name: 'Class', view: Class },
  { path: '/course', name: 'Course', view: Course },
  { path: '/video', name: 'Video', view: Video },
  { path: '/instructor', name: 'Instructor', view: Instructor },
  { path: '/planning', name: 'Planning', view: Planning },
  { path: '/studio', name: 'Studio', view: Studio },
  { path: '/podcast', name: 'Podcast', view: Podcast },
  // { path: '/user', name: 'User', view: User },
  { path: '/live', name: 'Live', view: Live },
  { path: '/event', name: 'Event', view: Event },
  { path: '/login', name: 'Login', view: Login },
  { path: '/transaction', name: 'Transaction', view: Transaction },
  { path: '/coupon', name: 'Coupon', view: Coupon },
  { path: '/giftcard', name: 'Giftcard', view: Giftcard },
  { path: '/health', name: 'Giftcard', view: Health },
];

export const navs = [
  { path: '/class', name: 'Class', navs: classNavs },
  { path: '/course', name: 'Course', navs: courseNavs },
  { path: '/video', name: 'Video', navs: videoNavs },
  // { path: '/user', name: 'User', navs: userNavs },
  { path: '/instructor', name: 'Instructor', navs: instructorNavs },
  { path: '/planning', name: 'Planning', navs: planningNavs },
  { path: '/studio', name: 'Studio', navs: studioNavs },
  { path: '/podcast', name: 'Podcast', navs: podcastNavs },
  { path: '/live', name: 'Live', navs: liveNavs },
  { path: '/event', name: 'Event', navs: eventNavs },
  { path: '/transaction', name: 'Transaction', navs: transactionNavs },
  { path: '/coupon', name: 'Coupon', navs: couponNavs },
  { path: '/giftcard', name: 'Giftcard', navs: giftcardNavs },
  { path: '/health', name: 'Health', navs: HealthNavs },
];
