import React from 'react';

const ListPlanning = React.lazy(() => import('../views/Planning/ListPlanning'));
const AddPlanning = React.lazy(() => import('../views/Planning/AddPlanning'));
const EditPlanning = React.lazy(() => import('../views/Planning/EditPlanning'));

const base = '/planning';

export const routes = [
  { path: `${base}/`, exact: true, name: 'Planning List', component: ListPlanning },
  { path: `${base}/add`, exact: true, name: 'Add Planning', component: AddPlanning },
  { path: `${base}/edit/:planid`, exact: true, name: 'Edit Planning', component: EditPlanning },
];

export const navs = [
  { path: `${base}/`, exact: false, name: 'Planning List' },
  { path: `${base}/add`, exact: true, name: 'Add Planning' },
];
