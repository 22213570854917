import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@ysc/ys-styles';
import { NavsContainer, StyledNavLink, BorderBottom } from './styles';

const NavBar = ({ navs }) => {
  return (
    <div>
      <NavsContainer>
        {navs.map((nav, index) => {
          return (
            <StyledNavLink exact={nav.exact} to={nav.path} key={parseInt(index, 10)}>
              <div>
                <Text>{nav.name}</Text>
              </div>
            </StyledNavLink>
          );
        })}
      </NavsContainer>
      <BorderBottom />
    </div>
  );
};

export default NavBar;

NavBar.propTypes = {
  navs: PropTypes.arrayOf(PropTypes.any),
};

NavBar.defaultProps = {
  navs: [],
};
