import React from 'react';

const AddVideo = React.lazy(() => import('../views/Video/AddVideo'));
const ListVideo = React.lazy(() => import('../views/Video/ListVideo'));
const EditVideo = React.lazy(() => import('../views/Video/EditVideo'));

const base = '/video';

export const routes = [
  { path: `${base}/add`, exact: true, name: 'Add Video', component: AddVideo },
  { path: `${base}/list`, exact: true, name: 'List Video', component: ListVideo },
  { path: `${base}/list/:videoid`, exact: true, name: 'Edit Video', component: EditVideo },
];

export const navs = [
  { path: `${base}/list`, exact: false, name: 'List Video' },
  { path: `${base}/add`, exact: false, name: 'Add Video' },
];
