import React from 'react';
import PropTypes from 'prop-types';
import { Input } from '@ysc/ys-styles';
import { Chip } from '@material-ui/core';

const TagsInput = ({ tags, name, formik }) => {
  const maxLength = 20;

  const handleKeyDown = (ev) => {
    const newTag = ev.target.value.substr(0, maxLength);
    if (ev.key === 'Enter') {
      ev.preventDefault();
      if (newTag !== '' && !tags.includes(newTag))
        formik.setValues({ ...formik.values, [name]: [...tags, newTag] });
      Object.assign(ev.target, { value: '' });
    }
  };

  const handleDelete = (idx) => {
    const newTags = tags.filter((tag, index) => {
      return index !== idx;
    });
    formik.setValues({ ...formik.values, [name]: newTags });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Input id={name} name={name} type="text" maxLength={maxLength} onKeyDown={handleKeyDown} />
      <div className="mt-xs">
        {tags.map((tag, index) => {
          return (
            <Chip
              key={index.toString()}
              style={{ margin: '3px' }}
              label={tag}
              onDelete={() => handleDelete(index)}
              variant="outlined"
            />
          );
        })}
      </div>
    </div>
  );
};

export default TagsInput;

TagsInput.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.any),
  name: PropTypes.string.isRequired,
  formik: PropTypes.objectOf(PropTypes.any),
};

TagsInput.defaultProps = {
  tags: [],
  formik: {},
};
