import styled from 'styled-components';
import { MainContainer as maincontainer, FlexContainer as flexcontainer } from '@ysc/ys-styles';
import { Fab } from '@material-ui/core';

export const drawerWidth = '300px';

export const FlexContainer = styled(flexcontainer)`
  width: 100%;
  overflow-x: hidden;
`;

export const MainContainer = styled(maincontainer)`
  box-sizing: border-box;
`;

export const Stretcher = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-left: ${(props) => (props.open ? 0 : `-${drawerWidth}`)};
  padding-bottom: 100px;
  @media only screen and (max-width: 600px) {
    ${(props) => (props.open ? 'filter: blur(5px); pointer-events: none;' : '')};
  }
  transition: margin-left 0.5s, filter 0.2s;
`;

export const DrawerOpener = styled(Fab)`
  &&& {
    position: fixed;
    bottom: 30px;
    left: 15px;
    width: 50px;
    height: 50px;
    z-index: 100;
    background-color: ${(props) => props.theme.color.bg.secondary};
  }
`;
