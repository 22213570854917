/* eslint-disable no-underscore-dangle */
import styled from 'styled-components';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';

export const StyledSideDrawer = styled(Drawer)`
  &&& {
    position: relative;
    width: ${(props) => props.drawerWidth};
    height: 100%;
    overflow-x: hidden;
    div {
      width: ${(props) => props.drawerWidth};
      background-color: ${(props) => props.theme.color.bg.secondary};
      overflow-x: hidden;
    }
  }
`;

export const StyledIconButton = styled(IconButton)`
  &&& {
    position: absolute;
    bottom: 30px;
    right: 10px;
    margin: 5px;
    color: ${(props) => props.theme.color.text.secondary};
    &:hover {
      background-color: ${(props) => props.theme.color.base.primary._80};
    }
  }
`;
