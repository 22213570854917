import React from 'react';

const EventTransaction = React.lazy(() => import('../views/Transaction/EventTransaction'));
const PlanTransaction = React.lazy(() => import('../views/Transaction/PlanTransaction'));
const CourseTransaction = React.lazy(() => import('../views/Transaction/CourseTransaction'));
const CreateEventTransaction = React.lazy(() =>
  import('../views/Transaction/EventTransaction/Form')
);

const base = '/transaction';

// plan event course

export const routes = [
  { path: `${base}/event`, exact: true, name: 'Overview', component: EventTransaction },
  { path: `${base}/plan`, exact: true, name: 'Plan Transaction', component: PlanTransaction },
  { path: `${base}/course`, exact: true, name: 'Course Transaction', component: CourseTransaction },
  {
    path: `${base}/event/create`,
    exact: true,
    name: 'Create Event Transaction',
    component: CreateEventTransaction,
  },
];

export const navs = [
  { path: `${base}/event`, exact: true, name: 'Event Transaction' },
  { path: `${base}/plan`, exact: false, name: 'Plan Transaction' },
  { path: `${base}/course`, exact: false, name: 'Course Transaction' },
];
