import React from 'react';
import { FlexContainer, Input } from '@ysc/ys-styles';
import Tooltip from '@material-ui/core/Tooltip';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import FilterContent from './FilterContent';

const TableTools = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <FlexContainer>
      <Tooltip title="Filter">
        <IconButton onClick={handleClick}>
          <FilterListIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Sort">
        <IconButton>
          <ImportExportIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Input style={{ marginLeft: 'auto' }} />

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <FilterContent />
      </Popover>
    </FlexContainer>
  );
};

export default TableTools;
