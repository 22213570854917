import React from 'react';

const StudioOverview = React.lazy(() => import('../views/Studio/Overview'));
const ListStudio = React.lazy(() => import('../views/Studio/ListStudio'));
const EditStudio = React.lazy(() => import('../views/Studio/EditStudio'));
const AddStudio = React.lazy(() => import('../views/Studio/AddStudio'));

const base = '/studio';

export const routes = [
  { path: `${base}/`, exact: true, name: 'Overview', component: StudioOverview },
  { path: `${base}/add`, exact: true, name: 'Add Studio', component: AddStudio },
  { path: `${base}/list`, exact: true, name: 'Studio List', component: ListStudio },
  { path: `${base}/edit/:studioid`, exact: true, name: 'Edit Studio', component: EditStudio },
];

export const navs = [
  { path: `${base}/add`, exact: true, name: 'Overview' },
  { path: `${base}/add`, exact: true, name: 'Add Studio' },
  { path: `${base}/list`, exact: false, name: 'Studio List' },
];
