const base = {
  main: '#FFFFFF',
  primary: {
    _100: '#333333',
    _80: '#666666',
    _60: '#999999',
    _40: '#CCCCCC',
  },
  secondary: {
    _100: '#EB5757',
    _80: '#EB7676',
    _60: '#EB8D8D',
    _40: '#EBA5A5',
  },
  brand: {
    _100: '#FED627',
    _80: '#FEDF59',
    _60: '#FEE98C',
    _40: '#FEF2BE',
  },
  info: {
    _100: '#2697FF',
    _80: '#4DA9FF',
    _60: '#80C2FF',
    _40: '#CCE7FF',
  },
  success: {
    _100: '#0EAB00',
    _80: '#3DAB33',
    _60: '#5CAB56',
    _40: '#7CAB78',
  },
  danger: {
    _100: '#DD0000',
    _80: '#E74545',
    _60: '#E77373',
    _40: '#E7A1A1',
  },
  warning: {
    _100: '#FFB800',
    _80: '#FFCD4D',
    _60: '#FFDB80',
    _40: '#FFEAB3',
  },
};
export default {
  color: {
    base,
    bg: {
      main: base.main,
      primary: base.primary._40,
      secondary: base.primary._100,
    },
    text: {
      primary: base.primary._100,
      secondary: base.main, // white
      brand: base.brand._100,
      red: base.secondary._100,
      danger: base.danger._100,
    },
    btn: {
      primary: base.primary._100,
      secondary: base.brand._100,
    },
    // },
    // text : {
    //     dark  :  primaryDark,
    //     light : primaryLight,
    //     red : primaryRed,
    // },
    // btn : {
    //   primary : {
    //     backgroundColor: dark,
    //     textColor: light,
    //     borderColor: none
    //   },
    //   secondary : {

    //   }

    // }
  },
};
