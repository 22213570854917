import React from 'react';
import PropTypes from 'prop-types';
import MuiDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Text } from '@ysc/ys-styles';

const Dialog = ({ open, handleClose, title, content, children }) => {
  return (
    <MuiDialog open={open} onClose={handleClose} aria-labelledby="dialog">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Text size="small">{content}</Text>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>{children}</DialogActions>
    </MuiDialog>
  );
};

export default Dialog;

Dialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Dialog.defaultProps = {
  open: false,
  handleClose: false,
  title: '',
  content: '',
};
