import styled from 'styled-components';
import TableCell from '@material-ui/core/TableCell';
import { Text } from '@ysc/ys-styles';
import Table from '@material-ui/core/Table';

export const ThinTableCell = styled(TableCell)`
  padding-top: 0 !important;
  padding-bottom: 0 !important;
`;

export const LoadMore = styled.div.attrs({ className: 'p-m' })`
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
`;

export const TableStyle = styled(Table)`
  padding: 14px !important;
  max-width: 480px;
  border: none;
  margin: 20px 40px;
`;

export const ThinAgeTableCell = styled(TableCell)`
  padding: 14px !important;
`;

export const TextTotal = styled(Text)`
  font-weight: bold;
  font-size: 14px;
`;

// classTable
export const TextHeader = styled(Text)`
  white-space: nowrap;
`;

export const ClassTitle = styled(Text)`
  padding-bottom: 10px !important;
`;

export const ClassImage = styled.img`
  width: 100%;
`;

export const PolicyTitle = styled(Text)`
  padding-bottom: 10px !important;
`;
