import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'

const FileUpload = ({ formik, name, maxCount = 1, accept }) => {
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (formik.values[name] === '' || formik.values[name] === null) {
      setFileList([]);
    } else setFileList([formik.values[name]]);
    // eslint-disable-next-line
  }, [formik.values[name]]);

  const props = {
    listType: 'picture-card',
    className: 'avatar-uploader',
    accept,
    onRemove: () => {
      formik.setValues({ ...formik.values, [name]: '' });
    },
    beforeUpload: (file) => {
      const formikData = { ...formik };
      formikData.touched[name] = true;
      formikData.setValues({ ...formik.values, [name]: file });
      return false;
    },
    maxCount,
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Upload fileList={fileList} {...props}>
      <UploadOutlined />
    </Upload>
  );
};

export default FileUpload;

FileUpload.propTypes = {
  formik: PropTypes.objectOf(PropTypes.any).isRequired,
  name: PropTypes.string.isRequired,
  maxCount: PropTypes.number,
  accept: PropTypes.arrayOf(PropTypes.any),
};
FileUpload.defaultProps = {
  maxCount: 1,
  accept: [],
};

// DEPRECATED
// export const VideoUpload = ({ formID, maxCount = 1, cancel }) => {
//   const { status, file, queue } = useContext(UploadContext);
//   const setFilename = file[1];
//   const queueDispatch = queue[1];
//   const [uploadStatus, setUploadStatus] = status;

//   const props = {
//     listType: 'picture-card',
//     className: 'avatar-uploader',
//     accept: 'video/*',
//     onRemove: () => {
//       // uploading -> cancel upload
//       if (uploadStatus[formID] === 'Uploading') {
//         cancel();
//         setUploadStatus((values) => ({ ...values, [formID]: undefined }));
//       }
//       // pending -> remove from queue
//       else if (uploadStatus[formID] === 'Pending') {
//         queueDispatch({ type: 'remove', formID });
//       }
//       // done or error -> remove
//       else {
//         setFilename((values) => ({ ...values, [formID]: '' }));
//         setUploadStatus((values) => ({ ...values, [formID]: undefined }));
//       }
//     },
//     beforeUpload: (fileData) => {
//       // uploading or pending -> change file
//       if (uploadStatus[formID] === 'Uploading') {
//         cancel();
//         queueDispatch({ type: 'push', formID, file: fileData });
//       } else if (uploadStatus[formID] === 'Pending') {
//         queueDispatch({ type: 'change', formID, file: fileData });
//       }
//       // null, done, or error -> push
//       else {
//         queueDispatch({ type: 'push', formID, file: fileData });
//       }
//       return false;
//     },
//     maxCount,
//     disable: uploadStatus[formID] === 'Uploading',
//   };

//   return (
//     // eslint-disable-next-line react/jsx-props-no-spreading
//     <Upload {...props}>
//       <UploadOutlined />
//     </Upload>
//   );
// };

// VideoUpload.propTypes = {
//   formID: PropTypes.string,
//   maxCount: PropTypes.number,
//   cancel: PropTypes.func.isRequired,
// };

// VideoUpload.defaultProps = {
//   formID: '',
//   maxCount: 1,
// };
