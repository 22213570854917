import React from 'react';

const AddEvent = React.lazy(() => import('../views/Event/AddEvent'));
const ListEvent = React.lazy(() => import('../views/Event/ListEvent'));
const EventOverview = React.lazy(() => import('../views/Event/Overview'));
const EditEvent = React.lazy(() => import('../views/Event/EditEvent'));
const EventDetail = React.lazy(() => import('../views/Event/EventDetail'));

const base = '/event';

export const routes = [
  { path: `${base}/`, exact: true, name: 'Overview', component: EventOverview },
  { path: `${base}/list`, exact: true, name: 'Event List', component: ListEvent },
  { path: `${base}/add`, exact: true, name: 'Add Event', component: AddEvent },
  { path: `${base}/edit/:eventid`, exact: true, name: 'Add Event', component: EditEvent },
  {
    path: `${base}/:eventid/detail`,
    exact: true,
    name: 'Event Detail Info',
    component: EventDetail,
  },
];

export const navs = [
  { path: `${base}/`, exact: true, name: 'Overview' },
  { path: `${base}/add`, exact: true, name: 'Add Event' },
  { path: `${base}/list`, exact: false, name: 'Event List' },
];
