import { css } from 'styled-components';

export const spacing = css`
  // margin extra small
  &.m-xs {
    margin: 8px !important;
  }
  &.ml-xs {
    margin-left: 8px !important;
  }
  &.mr-xs {
    margin-right: 8px !important;
  }
  &.mt-xs {
    margin-top: 8px !important;
  }
  &.mb-xs {
    margin-bottom: 8px !important;
  }

  // margin small
  &.m-s {
    margin: 16px !important;
  }
  &.ml-s {
    margin-left: 16px !important;
  }
  &.mr-s {
    margin-right: 16px !important;
  }
  &.mt-s {
    margin-top: 16px !important;
  }
  &.mb-s {
    margin-bottom: 16px !important;
  }

  // margin medium
  &.m-m {
    margin: 24px !important;
  }
  &.ml-m {
    margin-left: 24px !important;
  }
  &.mr-m {
    margin-right: 24px !important;
  }
  &.mt-m {
    margin-top: 24px !important;
  }
  &.mb-m {
    margin-bottom: 24px !important;
  }

  // margin large
  &.m-l {
    margin: 40px !important;
  }
  &.ml-l {
    margin-left: 40px !important;
  }
  &.mr-l {
    margin-right: 40px !important;
  }
  &.mt-l {
    margin-top: 40px !important;
  }
  &.mb-l {
    margin-bottom: 40px !important;
  }

  // margin extra large
  &.m-xl {
    margin: 60px !important;
  }
  &.ml-xl {
    margin-left: 60px !important;
  }
  &.mr-xl {
    margin-right: 60px !important;
  }
  &.mt-xl {
    margin-top: 60px !important;
  }
  &.mb-xl {
    margin-bottom: 60px !important;
  }

  // padding extra small
  &.p-xs {
    padding: 8px !important;
  }
  &.pl-xs {
    padding-left: 8px !important;
  }
  &.pr-xs {
    padding-right: 8px !important;
  }
  &.pt-xs {
    padding-top: 8px !important;
  }
  &.pb-xs {
    padding-bottom: 8px !important;
  }

  // padding small
  &.p-s {
    padding: 16px !important;
  }
  &.pl-s {
    padding-left: 16px !important;
  }
  &.pr-s {
    padding-right: 16px !important;
  }
  &.pb-s {
    padding-bottom: 16px !important;
  }
  &.pt-s {
    padding-top: 16px !important;
  }

  // padding medium
  &.p-m {
    padding: 24px !important;
  }
  &.pl-m {
    padding-left: 24px !important;
  }
  &.pr-m {
    padding-right: 24px !important;
  }
  &.pt-m {
    padding-top: 24px !important;
  }
  &.pb-m {
    padding-bottom: 24px !important;
  }

  // padding large
  &.p-l {
    padding: 40px !important;
  }
  &.pl-l {
    padding-left: 40px !important;
  }
  &.pr-l {
    padding-right: 40px !important;
  }
  &.pt-l {
    padding-top: 40px !important;
  }
  &.pb-l {
    padding-bottom: 40px !important;
  }

  // padding extra large
  &.p-xl {
    padding: 60px !important;
  }
  &.pl-xl {
    padding-left: 60px !important;
  }
  &.pr-xl {
    padding-right: 60px !important;
  }
  &.pt-xl {
    padding-top: 60px !important;
  }
  &.pb-xl {
    padding-bottom: 60px !important;
  }
`;
