import React from 'react';

const AddGiftcardDesign = React.lazy(() => import('../views/Giftcard/AddGiftcardDesign'));
const ListGiftcardDesign = React.lazy(() => import('../views/Giftcard/ListGiftcardDesign'));
const EditGiftcardDesign = React.lazy(() => import('../views/Giftcard/EditGiftcardDesign'));
const AddGiftcard = React.lazy(() => import('../views/Giftcard/AddGiftcard'));
const ListGiftcard = React.lazy(() => import('../views/Giftcard/ListGiftcard'));
const EditGiftcard = React.lazy(() => import('../views/Giftcard/EditGiftcard'));

const base = '/giftcard';

export const routes = [
  {
    path: `${base}/design/add`,
    exact: true,
    name: 'Add Giftcard Design',
    component: AddGiftcardDesign,
  },
  {
    path: `${base}/design/list`,
    exact: true,
    name: 'List Giftcard Design',
    component: ListGiftcardDesign,
  },
  {
    path: `${base}/design/edit/:giftcardDesignId`,
    exact: true,
    name: 'Edit Giftcard Design',
    component: EditGiftcardDesign,
  },
];

export const navs = [
  { path: `${base}/design/add`, exact: true, name: 'Add Giftcard Design' },
  { path: `${base}/design/list`, name: 'List Giftcard Design' },
  { path: `${base}/add`, exact: true, name: 'Add Giftcard', component: AddGiftcard },
  { path: `${base}/list`, exact: true, name: 'List Giftcard', component: ListGiftcard },
  { path: `${base}/edit/:giftcardid`, exact: true, name: 'Edit Giftcard', component: EditGiftcard },
];
