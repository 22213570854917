import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { NavBar } from '../components';
import { navs as mainNavs } from '../routes/main';

const TheTabs = () => {
  return (
    <Switch>
      {mainNavs.map((route, index) => {
        return (
          <Route key={parseInt(index, 10)} path={route.path}>
            <NavBar navs={route.navs} />
          </Route>
        );
      })}
    </Switch>
  );
};

export default TheTabs;
