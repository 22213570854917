import React, { useEffect } from 'react';
import { Prompt } from 'react-router-dom';

const PreventUnload = () => {
  useEffect(() => {
    const handleUnload = (e) => {
      // Cancel the event
      e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      // Chrome requires returnValue to be set
      e.returnValue = '';
    };
    window.addEventListener('beforeunload', handleUnload);
    return () => window.removeEventListener('beforeunload', handleUnload);
  }, []);
  return <Prompt when message="Leaving ? Changes you made may not be saved." />;
};

export default PreventUnload;
