export const size = {
  mobileS: '320px',
  mobileM: '480px',
  mobileL: '600px',
  tabletS: '768px',
  tabletM: '968px',
  tabletL: '1024px',
  laptopS: '1200px',
  laptopM: '1440px',
  laptop: '1698px',
};
export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tabletS: `(min-width: ${size.tabletS})`,
  tabletM: `(min-width: ${size.tabletM})`,
  tabletL: `(min-width: ${size.tabletL})`,
  laptopS: `(min-width: ${size.laptopS})`,
  laptopM: `(min-width: ${size.laptopM})`,
  laptop: `(min-width: ${size.laptop})`,
};
