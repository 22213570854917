import Cookies from 'js-cookie';
import history from '../HOC/history';
import instance from '../api/instance';

class Session {
  static admin;

  static tokenName = process.env.USER_DATA_TOKEN_NAME || 'admin';

  static getCookies() {
    try {
      this.admin = Cookies.get(this.tokenName);
    } catch (err) {
      this.admin = undefined;
    }
  }

  static isAdmin() {
    if (!this.admin) this.getCookies();
    return this.admin;
  }

  static destroy(path = '/login') {
    this.admin = undefined;
    Cookies.remove(this.tokenName);
    history.replace(path);
  }

  static requestLogout(callback = () => {}) {
    instance.get('/admin/logout').then((res) => callback(res.data));
    this.destroy();
  }
}

export default Session;
